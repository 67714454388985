import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "PilegDashboard",
  caps = "PILEGDASHBOARD",
}

@Module
export default class PilegDashboardModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  detail = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.id_party;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }

  @Action
  ["detail" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/vote/pilegs/${data.pileg_id}`)
        .then((res) => {
          // console.log(res.data.data);

          this.context.commit("SET_DETAIL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
  @Action
  ["All" + enums.name](data) {
    let params = "";
    if (data) {
      params = data.territory_id
        ? `&parent_id=${data.territory_id}`
        : params;
      params += data.party ? `&party=${data.party}` : params;
    }
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/vote/pilegs?years=2019${params}`)
        .then((res) => {
          this.context.commit("SET_ALL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
