import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "Dashboard",
  caps = "HEATMAP",
}
@Module
export default class DashboardModule extends VuexModule {
  error = null;
  msg = null;
  menu = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_MENU_" + enums.caps](data) {
    this.menu = data.menus;
  }

  @Action
  ["menu" + enums.name]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/user/menus`)
        .then((res) => {
          this.context.commit("SET_MENU_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }  
}
