import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import AccountConfirmModule from "@/store/modules/admin/account/AccountConfirmModule";
import PartiesModule from "./modules/admin/politic/PartiesModule";
import KaderModule from "./modules/admin/politic/KaderModule";
import KaderCategoryModule from "./modules/admin/politic/KaderCategoryModule";
import VolunteerModule from "./modules/admin/politic/VolunteerModule";
import TerritoryModule from "./modules/admin/master/TerritoryModule";
import ZoningsModule from "./modules/admin/vote/ZoningsModule";
import KtanisasiModule from "./modules/admin/vote/KtanisasiModule";
import KtanisasiSumsModule from "./modules/admin/vote/KtanisasiSumsModule";
import TerritoryLevelsModule from "./modules/admin/master/TerritoryLevelsModule";
import AttributionsModule from "./modules/admin/master/AttributionsModule";
import PositionModule from "./modules/admin/master/PositionModule";
import UsersModule from "./modules/admin/account/UsersModule";
import IssueDashboardModule from "./modules/dashboard/IssueDashboardModule";
import AdvocacyDashboardModule from "./modules/dashboard/AdvocacyDashboardModule";
import AttributeDashboardModule from "./modules/dashboard/AttributeDashboardModule";
import ViolationDashboardModule from "./modules/dashboard/ViolationDashboardModule";
import MovementDashboardModule from "./modules/dashboard/MovementDashboardModule";
import PersonDashboardModule from "./modules/dashboard/PersonDashboardModule";
import ActivityDashboardModule from "./modules/dashboard/ActivityDashboardModule";
import ZoningDashboardModule from "./modules/dashboard/ZoningDashboardModule";
import KTACadreDashboardModule from "./modules/dashboard/KTACadreDashboardModule";
import KTACountCadreDashboardModule from "./modules/dashboard/KTACadreCountDashboardModule";
import KTACandidateDashboardModule from "./modules/dashboard/KTACandidateDashboardModule";
import KTACountCandidateDashboardModule from "./modules/dashboard/KTACandidateCountDashboardModule";
import CadreDashboardModule from "./modules/dashboard/CadreDashboardModule";
import PilegDashboardModule from "./modules/dashboard/PilegDashboardModule";
import ResumeModule from "./modules/dashboard/ResumeModule";
import HeatmapModule from "./modules/dashboard/HeatmapModule";
import ComparisonModule from "./modules/dashboard/ComparisonModule";
import ResultModule from "./modules/dashboard/ResultModule";
import TPSModule from "./modules/dashboard/TPSModule";
import DashboardModule from "./modules/dashboard/DashboardModule";
import SapatokohDashboardModule from "./modules/dashboard/SapatokohDashboardModule";
import JaringtokohDashboardModule from "./modules/dashboard/JaringtokohDashboardModule";
import KolektifDashboardModule from "./modules/dashboard/KolektifDashboardModule";
import TokohDashboardModule from "./modules/dashboard/TokohDashboardModule";

config.rawError = true;

const store = createStore({
  state: {
    menu: "",
  },
  mutations: {
    SET_ACTIVE_MENU(state, data) {
      state.menu = data;
    },
  },
  actions: {
    setMenu({ commit }, data) {
      commit("SET_ACTIVE_MENU", data);
    },
  },
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    // Politic Module
    PartiesModule,
    KaderModule,
    KaderCategoryModule,
    VolunteerModule,
    // Vote Module
    ZoningsModule,
    KtanisasiModule,
    KtanisasiSumsModule,
    // Master Module
    TerritoryModule,
    TerritoryLevelsModule,
    AttributionsModule,
    PositionModule,
    // Account Module
    AccountConfirmModule,
    UsersModule,

    // Dashboard
    IssueDashboardModule,
    AdvocacyDashboardModule,
    AttributeDashboardModule,
    ViolationDashboardModule,
    MovementDashboardModule,
    PersonDashboardModule,
    ActivityDashboardModule,
    ZoningDashboardModule,
    KTACadreDashboardModule,
    KTACountCadreDashboardModule,
    KTACandidateDashboardModule,
    KTACountCandidateDashboardModule,
    CadreDashboardModule,
    PilegDashboardModule,
    ResumeModule,
    HeatmapModule,
    ComparisonModule,
    ResultModule,
    TPSModule,
    DashboardModule,
    SapatokohDashboardModule,
    JaringtokohDashboardModule,
    KolektifDashboardModule,
    TokohDashboardModule,
  },
});

export default store;
