import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "MovementDashboard",
  caps = "MOVEMENTDASHBOARD",
}

@Module
export default class MovementDashboardModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  detail = [];

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data.movements;
  }

  @Action
  ["All" + enums.name](data) {
    let params = ""
    if (data) {
      params += data.type ? `&type=${data.type}` : ""
      params += data.candidate ? `&candidate_id=${data.candidate}` : ""
    }
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/app/movements?page=1${params}`)
        .then(({data}) => {
          if (!data.type) {
            this.context.commit("SET_ALL_" + enums.caps, data.data.movements);
          }
          else {
            this.context.commit("SET_ALL_" + enums.caps, data.data);
          }
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["detail" + enums.name](data) {
    let params = "";
    if (data) {
      params = data.caleg ? `?caleg_id=${data.caleg}` : "";
    }
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`api/v1/app/movements${params}`)
        .then((res) => {
          this.context.commit("SET_DETAIL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
