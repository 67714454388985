import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export enum enums {
  name = "PersonDashboard",
  caps = "PERSONDASHBOARD",
}

export interface Data {
  person: {
    id: number;
    name: string;
    phone_number: string;
    territory_id: number;
    alamat: string;
    rt: string;
    rw: string;
    position: string;
    profession: string;
    pandangan: string;
    foto: string;
    latitude: string;
    longitude: string;
  };
}

@Module
export default class PersonDashboardModule extends VuexModule {
  error = null;
  msg = null;
  all = [];
  detail = [];

  get FilterPersonDashboard() {
    return (type: string) => {
      this.all.filter((data: Data) => data.person.pandangan === type);
    };
  }

  @Mutation
  ["SET_MSG_" + enums.caps](data) {
    this.msg = data;
  }
  @Mutation
  ["SET_ERROR_" + enums.caps](data) {
    this.error = data;
  }
  @Mutation
  ["SET_ALL_" + enums.caps](data) {
    this.all = data.persons;
  }
  @Mutation
  ["SET_DETAIL_" + enums.caps](data) {
    this.detail = data;
  }

  @Action
  ["All" + enums.name](data) {
    let params = "";
    if (data) {
      params = data.kabupaten ? `&kabupaten_id=${data.kabupaten}` : "";
      params += data.kecamatan ? `&kecamatan_id=${data.kecamatan}` : "";
      params += data.kelurahan ? `&territory_id=${data.kelurahan}` : "";
      params += data.start_date
        ? `&start_date=${data.start_date}`
        : `&start_date=${new Date().toISOString().slice(0, 10)}`;
      params += data.end_date
        ? `&end_date=${data.end_date}`
        : `&end_date=${new Date().toISOString().slice(0, 10)}`;
    }
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/app/persons?pandangan=pro" + params)
        .then((res) => {
          // if (res.data.data.total > 0) {
            this.context.commit("SET_ALL_" + enums.caps, res.data.data);
            resolve();
          // } else {
          //   alert(res.data.data.total);
          // }
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }

  @Action
  ["getDetail" + enums.name](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/v1/app/persons/" + data)
        .then((res) => {
          this.context.commit("SET_DETAIL_" + enums.caps, res.data.data);
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            "SET_ERROR_" + enums.caps,
            err.response.data.message
          );
          reject();
        });
    });
  }
}
